import React, { Component } from 'react';
import sec2_sec_3 from '../../App/images/sec-2-3.png'
import $ from 'jquery'
import Email from '../Email'
import closeVideo from '../../App/images/close.svg'

const isMobile = !!(function () {
    return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
})();
const isPc = !isMobile

function Dispatch(video) {
    this.video = video
    this.$parent = $(this.video)
        .parents()
        .find('.vid-wrapper-out')
    this.init()
}
Dispatch.prototype.init = function () {
    const _this = this
    $(document).keydown(function (e) {
        if (e.keyCode === 27) {
            _this.close()
        }
    })
    this.$parent.on('click', '.close', function () {
        _this.close()
    })
}
Dispatch.prototype.show = function () {
    if (isPc) {
        this.$parent.css('z-index', 10).css('left', '0')
    }
    this.video.play()
}
Dispatch.prototype.close = function () {
    if (isPc) {
        this.$parent.css('z-index', -1000).css('left', '-10000px')
    }
    this.video.pause()
}


class Pixure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:true
        }
    }
    play = () => {
        this.d.show()
    }
    close = () => {
        this.d.close()
    }
    componentDidMount() {
        this.d = new Dispatch(this.video)
    }
    render() {
        const {data}=this.state;
        return (
        <><section id="pixure" className="pixure">
            <ul className="pixure-outputs">
                <li className="pixure-outputs-item">input::back_photo;</li>
                <li className="pixure-outputs-item">input::side_photo;</li>
                <li className="pixure-outputs-item">pixure calculating</li>
                <li className="pixure-outputs-item">print body_mesasurements</li>
            </ul>
            <ul className="pixure-list">
                <li className="pixure-list-item getmore" onClick={this.play}>了解更多</li>
                <li className="pixure-list-item download" onClick={()=>{this.props.getData(data)}}><Email style={{color: '#fff'}} >立即下载</Email></li>
                <li className="pixure-list-item entry"><Email style={{color: '#fff'}} href='https://www.wepixure.com/login'>进入后台</Email></li>
            </ul>
            <img className="sec2-sec3-img" src={sec2_sec_3} alt='img' />
        </section>
            <div className="vid-wrapper-out">
                <div onClick={this.close} className="vid-close">
                    <img src={closeVideo} alt='img' />
                </div>
                <div id="video-wrap" className="vid-wrapper">
                    <video id="video" ref={video => this.video = video} src="http://weicodeai.oss-cn-beijing.aliyuncs.com/wecodeai_help_small.mp4" controls></video>
                </div>
            </div></>
        );
    }
}

export default Pixure;