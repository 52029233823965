import React, { useEffect,Component } from 'react';
import './base.scss'
import './index.scss'
import logo from './images/logo.png'
import home_text from './images/home-text.png'
import sec1_sec_2 from './images/sec-1-2.png'
import qrcode from './images/qrcode.png'
import down from './images/down_icon.png'
import Pixure from '../components/Pixure'
import Email from '../components/Email'
import initScroll from './scroll'
import WePattern from './WePattern'
import mini from './images/webpanduanma.png'
import Modal from '../components/Modal'
import Dialog from '../Dialog.js'
import Down from '../down'
import{Route,Switch,Redirect} from 'react-router-dom'
const FixHeader = () => (
    <div className="fix-header" id="fix-header">
        <div className="container-pc">
            <div className="logo"></div>
            <div className="nav">
                <a data-no='0' className="item" href="/#home">Home</a>
                <a data-no='1' className="item" href="/#pixure">Pixure</a>
                <a data-no='2' className="item" href="/#size-finder">Size Finder</a>
                <a data-no='3' className="item" href="/#w-wardrobe">W Wardrobe</a>
                <a data-no='4' className="item" href="/#we-pattern">We Pattern</a>
                <a data-no='5' className="item" href="/#developer">Developer</a>
                <a data-no='6' className="item" href="/#join-us">Join us</a>
            </div>
        </div>
    </div>
)
const JoinUs = () => (
    <section id="join-us" className="join-us">
        <div className="texts">
            <p>WeCode.ai属于所有充满好奇心的孩子</p>
            <p>无论是研发、设计、内容创作...</p>
            <p>请把你有趣的灵魂安放在这里</p>
            <p>简历请发送至hr@wecodeeai.com</p>
            <p>上海市杨浦区国定东路200号2号楼5楼</p>
            <p>Welcome aboard！</p>
        </div>
        <div className="copyright">
            <p>Copyright © 2018 WeCode.ai Inc.</p>
            <p>All rights reserved. 上海未马智能科技有限公司</p>
            <p><Email style={{color: '#666'}} href="http://beian.miit.gov.cn" target="_blank">沪ICP备17051787号</Email></p>
        </div>
        <div className="qrcodes">
            <figure>
                <img src={qrcode} alt='img' />
                <figcaption>微信公众号</figcaption>
            </figure>
            <figure>
                <img src={down} alt='img' />
                <figcaption>Pixure App</figcaption>
            </figure>
            <figure>
                <img src={mini} alt='img' />
                <figcaption>判码小程序</figcaption>
            </figure>
        </div>
    </section>
)
const Home = () => (
    <div id="home" className="home">
        <img className="home-logo" src={logo} alt='img' />
        <img className="home-title" src={home_text} alt='img' />
        <p className='home-text'>
            未马智能（WeCode.ai）成立于2017年10月，由前硅谷数据工程师、
        </p>
        <p className='home-text'>前Casio高级工程师、前SMG制片人、服装产业权威人士及其团队共同创建。</p>
        <p className='home-text'>
            WeCode.ai致力于类脑计算技术在时尚产业的研发与应用，
        </p>
        <p className='home-text'>
            创造具备感知、识别、判断及决策能力的人工大脑FashionBrain，
        </p>
        <p className='home-text'>
            赋能于所有时尚产业从业者，为大众打造更简单的生活方式。
        </p>
        <img className="sec1-sec2-img" src={sec1_sec_2} alt='img' />
    </div>
)
const WWardrobe = () => (
    <section id="w-wardrobe" className="w-wardrobe">
        <ul className="w-wardrobe-list">
            <li className="w-wardrobe-list-item"><Email>申请开店</Email></li>
            <li className="w-wardrobe-list-item"><Email href='http://www.wwardrobe.wecodeai.com'>进入后台</Email></li>
        </ul>
    </section>
)
const SizeFinder = () => (
    <section id="size-finder" className="size-finder">
        <div className="apply-now">
            <Modal><span>立即使用</span></Modal>
        </div>
    </section>
)
// const Appshow = () => {
//         useEffect(initScroll, [])
//         return (
//             <div id="out" className='out'>
//                 <FixHeader />
//                 <Home />
//                 <Pixure/>
//                 <SizeFinder />
//                 <WWardrobe />
//                 <WePattern />
//                 <JoinUs />
//             </div>
//         );
//     }
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog :false
        }
    }
    hideAllMenu = () => {
         console.log('hideAllMenu')
        if(this.state.dialog){
            this.setState({
                dialog :false
            })
        }
    }
    getData=(data)=>{
        this.setState({
            dialog : data
        })
      }
    scrollToAnchor = (e,anchorName) => {
        console.log(anchorName)
    if (anchorName) {
        // 找到锚点
        let anchorElement = document.getElementById(anchorName);
        // 如果对应id的锚点存在，就跳转到锚点
        if(anchorElement) { anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'}); }
    }
  }
    render(){
        const {dialog}=this.state;
        return(
            <Switch>
            <Route exact path="/">
            <div id="out" className='out' onClick={this.hideAllMenu}>
               <div className="fix-header" id="fix-header">
        <div className="container-pc">
            <div className="logo"></div>
            <div className="nav">
                <a data-no='0' className="item" onClick={(e)=>{this.scrollToAnchor(e,'home')}}>Home</a>
                <a data-no='1' className="item" onClick={(e)=>{this.scrollToAnchor(e,'pixure')}}>Pixure</a>
                <a data-no='2' className="item" onClick={(e)=>{this.scrollToAnchor(e,'size-finder')}}>Size Finder</a>
                <a data-no='3' className="item" onClick={(e)=>{this.scrollToAnchor(e,'w-wardrobe')}}>W Wardrobe</a>
                <a data-no='4' className="item" onClick={(e)=>{this.scrollToAnchor(e,'we-pattern')}}>We Pattern</a>
                <a data-no='5' className="item" onClick={(e)=>{this.scrollToAnchor(e,'developer')}}>Developer</a>
                <a data-no='6' className="item" onClick={(e)=>{this.scrollToAnchor(e,'join-us')}}>Join us</a>
            </div>
        </div>
    </div>
                <Home />
                <Pixure getData={this.getData}/>
                <SizeFinder/>
                <WWardrobe />
                <WePattern />
                <JoinUs />
                {dialog&&<Dialog/>}
            </div>
            </Route>
            <Route path="/down">
                <Down/>
            </Route>
        </Switch>
        )
    }
}
export default App;