import React from 'react';
import Email from '../../components/Email'
import hezuo from '../images/shangwuhezuo.png'
export default () => {
    return (<section id="we-pattern" className="we-pattern">
        <div className="fixed-hezuo">
            <Email><img src={hezuo} alt='images' /></Email>
        </div>
        <div id="developer" className="intro">
            <p className="intro-tip">WeCode.ai 开放平台</p>
            <p className="intro-tip">愿与每一位AI Developer合作</p>
            <p className="intro-tip">共享我们的科技成果</p>
            <p className="intro-join"><Email style={{ color: '#fff' }}>申请加入</Email></p>
        </div>

    </section>)
}