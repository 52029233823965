import './index.css';
import React from 'react';

const Dialog = ()=>{
    // const {message,close} = props;
    return (
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-body"/>
            </div>
        </div>
    )
}
export default Dialog