import React, { Component } from 'react';
import {createPortal} from 'react-dom'
import mini from '../../App/images/mini.jpeg'
import './index.scss'
import closeSvg from '../../App/images/close.svg'
var root = document.createElement('div')
root.classList.add('layer')
const withLayer = WrapperComponent =>
     class Layer extends Component {
    
    
    render() {
        return createPortal(<WrapperComponent {...this.props}/>, document.body)
    }
}
const Inner = (props) => {
    return (<div className='layer'>
        <div className='close'>
            <img onClick={props.onClick} src={closeSvg} alt='close-svg'/>
        </div>
        <img src={mini} alt='qrcode'/>
    </div>)
}
class Modal extends Component {
    state = {
        show: false
    }
    close = () => {
        this.setState({show: false})
    }
    onClick=()=> {
        this.setState({
            show: true
        })
    }
    render() {
        return <>{React.cloneElement(this.props.children, {
            onClick: this.onClick
        })}
        {this.state.show&&<Inner onClick={this.close}/>}
        </>
    }
}
export default Modal