import React, { Component } from 'react';
import './index.scss'

class Down extends Component{
    constructor(props) {
        super(props);
        this.state = {}
    }
    isWx = () =>{
        var ua = navigator.userAgent.toLowerCase();
           if(ua.match(/MicroMessenger/i)=="micromessenger") {
               //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
            //    wx.miniProgram.getEnv((res)=>{
            //       if (res.miniprogram) {
                      alert("请在浏览器中打开该页面");
                      return false;
                //   } else {
                //     alert("qqq");
                //   }
            //    })
           }else{
            // alert("ccc");
            return true
           }
       }
    goAndroid = () =>{
        const isWx  = this.isWx();
        if(isWx){
            window.open("https://weicodeai.oss-cn-beijing.aliyuncs.com/Pixure_release.apk");
        }
    }
    goAndroidENG = () =>{
        const isWx  = this.isWx();
        if(isWx){
            window.open("https://wecodeaieng.oss-eu-west-1.aliyuncs.com/Pixure_release_eng.apk");
        }
    }
    goIos = () =>{
        window.open("https://apps.apple.com/us/app/pixure%E6%8B%8D%E7%85%A7%E9%87%8F%E8%BA%AB/id1331862725?l=zh&ls=1");
    }
    render() {
        return(
            <div className="content">
                <div className="content_main"/>
                <div className="posi_div" onClick={this.goAndroid}>
                </div>
                <div className="posi_eng_div" onClick={this.goAndroidENG}>
                </div>
                <div className="main_icon"  onClick={this.goIos}>
                </div>
            </div>
        )
    }
}
export default Down;