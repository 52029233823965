import React from 'react';
const Email = (data={}) => (options) => {
    const all = {...data, ...options}
    const {children,...rest} = all
    return <a {...rest} >{children}</a>
}
const mailto = {
    style: {
        color: 'black',
        cursor: 'pointer'
    },
    href:'mailto:contact@wecodeai.com'
}
export default Email(mailto)

